import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doLogout as  doLogoutAction} from "actions/login";

export default () => {
  // bind actions
  const dispatch = useDispatch();
  const doLogout = () => dispatch(doLogoutAction());

  useEffect(() => {
    doLogout();
  }, []);

  return (
    <div>** Process logout</div>
  )
}